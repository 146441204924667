import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import CardMedia from '@mui/material/CardMedia';
import ImageList from '@mui/material/ImageList';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme } from '@mui/material/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';

import Container from 'components/Container';


const AnnualPrize1and22024 = () => {

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/1.webp`;
    const p2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/2.webp`;
    const p3 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/3.webp`;
    const p4 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/4.webp`;
    const p5 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/5.webp`;
    const p6 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/6.webp`;
    const p7 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/7.webp`;
    const p8 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/8.webp`;
    const p9 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annual1and2/9.webp`;

    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
    ];

    return (
        <Container>
            <Box>
                <Box marginBottom={4}>
                    {/* <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
            ACHIEVING MILESTONES
          </Typography> */}
                    <Typography fontWeight={700} variant={'h4'} align={'center'}>
                    Prize Day of Classes 1 and 2
                    </Typography>
                    <br />
                    <Typography
                        fontSize="x-small"
                    ><br /><br />
                        Classes: 1 and 2      Date: 12.02.2025
                    </Typography>
                    <Typography variant={'subtitle1'} align={'justify'}>
                        National Public School Yeshwanthpur, organised a Felicitation Ceremony for the students of
                        Classes 1 and 2 on Wednesday, 12 February 2025 in the school premises. The winners of the
                        various
                        <br></br>
                        Inter-school Sports and CCA competitions held in various subjects were called on the stage and
                        felicitated by the Principal and the teachers.
                        <br></br>
                        The ceremony concluded by an appreciation by our beloved Principal, who commended the
                        participants for their efforts and motivated the audience to enthusiastically participate in the
                        upcoming competitions.
                        <br></br>
                    </Typography>
                </Box>
                <Typography
                    variant={'h6'}
                    color={'primary'}
                    align={'center'}
                    fontWeight={'normal'}
                ><br />
                    ‘Persistence is the quality of winners. Successful people never give up’
                    <br />
                    <br />
                </Typography>
                {/* <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Card
                sx={{
                  boxShadow: 0,
                  background: 'transparent',
                  backgroundImage: 'none',
                }}
              >
                <Box
                  component={CardMedia}
                  borderRadius={2}
                  width={1}
                  height={1}
                  minHeight={320}
                  image={item.avatar}
                />
                <Box
                  component={CardContent}
                  bgcolor={'transparent'}
                  marginTop={-5}
                >
                  <Box component={Card}>
                    <CardContent>
                      <ListItemText
                        primary={item.name}
                        secondary={item.title}
                      />
                    </CardContent>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid> */}
                <Box>
                    <ImageList
                        variant="quilted"
                        cols={2}
                        rowHeight={isMd ? 300 : 200}
                        gap={isMd ? 16 : 4}
                    >
                        {photos.map((item, i) => (
                            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                <LazyLoadImage
                                    height={'100%'}
                                    width={'100%'}
                                    src={item.src}
                                    alt="..."
                                    effect="blur"
                                    onClick={() => openLightbox(i)}
                                    style={{
                                        objectFit: 'cover',
                                        filter:
                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                        cursor: 'poiner',
                                        borderRadius: 8,
                                    }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
                {viewerIsOpen && (
                    <Lightbox
                        mainSrc={photos[currentImage].src}
                        nextSrc={photos[(currentImage + 1) % photos.length].src}
                        prevSrc={
                            photos[(currentImage + photos.length - 1) % photos.length].src
                        }
                        onCloseRequest={() => closeLightbox()}
                        onMovePrevRequest={() =>
                            setCurrentImage((currentImage + photos.length - 1) % photos.length)
                        }
                        onMoveNextRequest={() =>
                            setCurrentImage((currentImage + 1) % photos.length)
                        }
                        reactModalStyle={{ overlay: { zIndex: 1500 } }}
                    />
                )}
                {/* </Box> */}
            </Box>
        </Container>
    );
};

export default AnnualPrize1and22024;